<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-width="50"
    :nudge-bottom="10"
    offset-y
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="ml-2 pr-1"
        :class="{'fill-width d-flex justify-center': $vuetify.breakpoint.xsOnly, }"
        style="overflow: visible !important"
        label
        outlined
        v-bind="attrs"
        v-on="on"
      >
      <span class="font-weight-bold">
        Ordenar: <strong>{{ selected[0].text }}</strong>
        <v-icon
          size="22"
          class="ml-1 pr-0"
          v-text="'mdi-menu-down'"
        />
      </span>
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="px-0">
        <v-row>
          <v-col cols="12">
            <v-list class="py-0">
              <v-list-item-group v-model="selected_sort">
                <v-list-item
                  v-for="(item, i) in options"
                  :key="'optionSort_'+i"
                  :value="item.value"
                >
                  <v-list-item-icon class="mr-1">
                    <v-icon color="icono" v-text="'mdi-text-box-check-outline'" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'BtnSort',
  props:{
    value: String,
  },
  data () {
    return {
      selected_sort: this.value,
      options: [
        {
          text: 'Código Cliente',
          value: 'co_cli',
        },
        {
          text: 'Hora Inicio',
          value: 'hora_inicio',
        },
      ]
    }
  },
  computed:{
    selected () {
      return this.options.filter(i => i.value === this.selected_sort)
    },
  },
  watch:{
    value (val, oldVal) {
      if(val === oldVal) return

      this.selected_sort = val
    },
    selected_sort (val, oldVal) {
      if(val === oldVal) return

      this.$emit('input', val)
    }
  }
}
</script>
